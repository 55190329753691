import $ from "jquery";

$(function(){

    // let header = document.querySelector('header');
    // const observer = new IntersectionObserver( 
    //     ([e]) => e.target.classList.toggle("is-stuck", e.intersectionRatio < 1),
    //     { threshold: [1] }
    //   );
      
    //   observer.observe(header);

    $(window).scroll(function() {
      var header = $('.header-top ');
      if(window.scrollY > 1) {
        header.addClass('is-stuck');
      } else {
        header.removeClass('is-stuck');
      };
      });
      
});