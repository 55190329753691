import $ from 'jquery'
import toastr from '../components/toastr'

let page = 2;

const loadMoreFeaturedProductsButton = $("#load-more-featured-products");

loadMoreFeaturedProductsButton.on('click', function(){
    $.get(ajaxFeaturedProductsUrl + "?loadMoreFeaturedProducts=true&page=" + page)
    .then(function(resp){
        const isFinalPage = resp.finalPage;

        if (isFinalPage) loadMoreFeaturedProductsButton.hide();

        // On success append the products
        $(".featured-products .products").append(resp.preview);
        page++;
    })
    .fail(function(resp){
        // On fail display message - errorMessage is setted in main tpl file
        // You can translate it in Prestashop administration 
        toastr['error'](errorMessage);
    });
});
