import Popper from "popper.js";
import { waitForElm } from "../helpers";

waitForElm(".autocomplete-suggestions").then((pop) => {
    const ref = document.querySelector(".search-input");
    console.log(pop);
    console.log(ref);
    const popper = new Popper(ref, pop, {
        positionFixed: true,
        modifiers: {
            offset: '0, 8',
            preventOverflow: { enabled: true },
        },
    });

    // Create an observer instance.
    ['focus', 'input'].forEach((listener) => {
        ref.addEventListener(listener, () => {
            if (pop.matches(':not([style*="display: none"])')) popper.update()
        });
    });
    var observer = new MutationObserver(function (mutations) {
        popper.update();
    });

    // Pass in the target node, as well as the observer options.
    observer.observe(pop, {
        attributes: false,
        childList: true,
        characterData: true,
    });
});
